<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import SecondaryButton from "@/Components/SecondaryButton.vue";
import SearchInputHome from "@/Components/SearchInputHome.vue";
import ProfilesGridList from "@/Components/ProfilesGridList.vue";
import CTASection from "@/Pages/Partials/CTASection.vue";
import RegisterNowSection from "@/Pages/Partials/RegisterNowSection.vue";
import UserAvatar from "@/Components/UserAvatar.vue";
import {router} from "@inertiajs/vue3";
import {ref} from "vue";
import 'swiper/css';

defineProps({
    profilesCount: Number,
    profiles: Array,
})
const query = ref('');

</script>

<template>
    <AppLayout>
        <div class="px-4 sm:px-16 lg:px-28 max-w-7xl mx-auto">
            <div class="flex flex-col sm:flex-row py-4 gap-20">
                <div class="flex flex-col md:w-1/2">
                    <div class="px-8">
                        <img class="md:hidden" src="/storage/images/home_photo_1.png" alt="home-img"/>
                    </div>
                    <div class="flex flex-col sm:gap-4 lg:gap-0 sm:flex-row justify-center items-center">
                        <div class="flex flex-col items-center justify-center gap-1 text-teal-900 text-2xl sm:text-4xl md:text-6xl font-medium flex-wrap p-8">
                            <div class="text-center md:text-start font-lora">
                                {{ $t('Your ') }}
                                <span class="text-teal-500 text-2xl sm:text-4xl md:text-6xl font-medium"
                                    style="white-space: nowrap;">{{ $t('life') }}
                                </span>
                              {{ $t(' story. Remembered') }}
                                <span class="text-teal-500 text-2xl sm:text-4xl md:text-6xl font-medium"
                                      style="white-space: nowrap;">{{ $t('forever') }}
                                </span>
                            </div>
                            <div class="flex items-center justify-center text-center md:text-start text-black text-base font-normal mt-6">
                                {{$t('We provide a platform for individuals all around the world to preserve and share life stories, memories, and biographies')}}
                            </div>
                            <SearchInputHome v-model="query" classes="border-teal-700" class="text-center mt-9 sm:w-full" @onSearch="router.visit(route('explore', {query: query}))"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col justify-center items-center hidden md:block w-full h-full">
<!--                    <SwiperHomepage></SwiperHomepage> //disabled until requested-->
                    <img src="/storage/images/home_photo_1.png" alt="home-img"/>
                </div>
            </div>
            <!--Team section start-->
            <div class="text-center px-12 py-16">
                <span class="text-teal-500 text-2xl md:text-4xl font-medium font-lora">{{ profilesCount }}</span>
                <span class="text-teal-900 text-2xl md:text-4xl font-medium font-lora ml-2">{{$t('people immortalized') }}</span>
            </div>
            <div class="md:mx-8" v-if="profiles?.length">
                <ProfilesGridList :profiles="profiles"/>
                <div class="flex items-center justify-center mb-24 sm:mb-0">
                    <SecondaryButton :lg="true"
                                     @click="$inertia.visit(route('explore'))"
                                     class="w-full sm:w-auto"
                    >
                        {{ $t('See more people') }}
                    </SecondaryButton>
                </div>
            </div>
            <!--Team section end-->
            <!--Hero section one start-->
            <div class="flex flex-row py-8 md:py-16 relative hidden sm:block">
                <div class="w-full h-[560px] bg-right bg-cover sm:bg-contain bg-no-repeat rounded-lg p-5 sm:p-0"
                     style="background-image: url('/storage/images/hero-img.png');">
                    <div class="flex flex-col w-4/6">
                        <div class="text-teal-500 text-base font-semibold mt-2 sm:mt-[74px]">
                            {{ $t('Stories you learn from') }}
                        </div>
                        <div class="text-teal-900 text-2xl sm:text-4xl md:text-5xl font-medium font-lora mt-4">Went to
                            the Alps and learned to not fear it
                        </div>
                        <div class="text-slate-600 text-base sm:text-lg md:text-xl font-normal mt-6">This was a significant milestone for me. It was one of my biggest fears - the height. Now that I have faced it, I would do it again!
                        </div>
                        <UserAvatar :xl="true"
                                    :user="{full_name: 'Andrew Walter', location: 'Chicago, USA', profile_photo_url: '/storage/images/avatar.png'}"
                                    :show-location="true"
                                    class="mt-2 md:mt-12">
                        </UserAvatar>
                        <div class="mt-12">
                            <SecondaryButton :lg="true" @click="$inertia.visit(route('explore'))">
                                {{$t('See more stories')}}
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
                <img
                    src="/storage/images/hand-drawn-arrow.png"
                    alt="hand-arrow"
                    class="absolute bottom-10 left-1/2 -translate-x-1/2 max-w-[287px] hidden lg:block"
                />
            </div>
            <!--mobile only-->
            <div class="flex flex-row relative sm:hidden">
                <div class="flex flex-col">
                    <div class="flex-1">
                        <img class="object-contain" src="/storage/images/hero-img.png" alt="hero"/>
                    </div>
                    <div class="flex-1 flex flex-col justify-center items-start mt-6">
                        <div class="text-teal-500 text-base font-semibold mt-2">{{ $t('Stories you learn from') }}</div>
                        <div class="text-teal-900 text-2xl font-medium font-lora mt-4">Went to the Alps and learned to
                            not fear it
                        </div>
                        <div class="text-slate-600 text-base font-normal mt-6">
                            This was a significant milestone for me. It was one of my biggest fears - the height. Now that I have faced it, I would do it again!
                        </div>
                        <UserAvatar :xl="true"
                                    :user="{full_name: 'Andrew Walter', location: 'Chicago, USA', profile_photo_url: '/storage/images/avatar.png'}"
                                    :show-location="true"
                                    class="mt-4 md:mt-12">
                        </UserAvatar>
                        <div class="mt-12 w-full">
                            <SecondaryButton :lg="true" @click="$inertia.visit(route('explore'))" class="w-full">
                                {{ $t('See more stories') }}
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
            </div>
            <!--Hero section one end-->
            <!--Hero section two start-->
            <div class="flex mt-24 md:mt-40 gap-12 flex-col sm:flex-row">
                <div class="flex-1">
                    <img class="object-contain" src="/storage/images/hero-two.png" alt="hero"/>
                </div>
                <div class="flex-1 flex flex-col justify-center items-start">
                    <div class="text-teal-500 text-base font-semibold">{{ $t('Improve your life') }}</div>
                    <div class="text-teal-900 text-2xl sm:text-4xl md:text-5xl font-medium font-lora mt-4">
                        {{ $t('Meaningful connections from mentors you choose') }}
                    </div>
                    <div class="text-slate-600 text-base sm:text-lg md:text-xl font-normal mt-6">
                        {{ $t('Find someone you admire and make them your mentor. It’s that easy!') }}
                    </div>
                    <div class="mt-12 w-full">
                        <SecondaryButton :lg="true"
                                         @click="$inertia.visit('/about', {
                                             data: {
                                                'viewMoreFeatures': true
                                             }
                                         })"
                                         class="w-full sm:w-auto"
                        >
                            {{$t('See more features') }}
                        </SecondaryButton>
                    </div>
                </div>
            </div>
            <!--Hero section two end-->
            <!--Pricing section start  - disable for now-->
<!--            <div class="flex mt-24 md:mt-40 gap-16 flex-col md:flex-row">-->
<!--                <div class="flex-1">-->
<!--                    <div class="flex flex-col">-->
<!--                        <div class="text-teal-500 text-base font-semibold">{{ $t('Simple, transparent pricing') }}</div>-->
<!--                        <div class="text-teal-900 text-2xl sm:text-4xl md:text-5xl font-medium font-lora mt-4">-->
<!--                            {{ $t('It’s free to live forever') }}-->
<!--                        </div>-->
<!--                        <div class="text-slate-600 text-base sm:text-lg md:text-xl font-normal mt-6 mb-8">-->
<!--                            {{ $t('We do believe Immortalize should be for everyone') }}-->
<!--                        </div>-->
<!--                        <img class="max-h-[274px] object-contain" src="/storage/images/discount-section-img.png"-->
<!--                             alt="discount"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <BasicPlan/>-->
<!--            </div>-->
            <!--Pricing section end-->
            <CTASection/>
            <RegisterNowSection/>
        </div>
    </AppLayout>
</template>
